import * as React from 'react'
import { navigate } from 'gatsby'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  IconPayPal,
  IconLoading,
  IconAffirm,
  IconBank,
  IconCoin,
  IconCreditCard,
  IconAlert,
} from '../IconsCheckout'
import { changeStatus, CHECKOUT_STATUS_FAILED } from '../../state/cart'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'

const CheckoutSummary = ({ setStep }) => {
  const checkout = useSelector(state => state.checkout)
  const cartStatus = useSelector(state => state.cart.status)
  const cartStatusMessage = useSelector(state => state.cart.status_message)
  const [isWorking, setIsWorking] = React.useState(true)
  const [isComplete, setIsComplete] = React.useState(false)
  const dispatch = useDispatch()

  const stripe = useStripe()
  const elements = useElements()

  const finalizePayment = e => {
    setIsWorking(true)
    let id = e?.id || checkout.order_id
    let uid = e?.uid || checkout.order_uid
    let url = e?.url || checkout.order_url
    const return_url =
      `/thank-you-shopping/?p=${checkout.payment_method}&s=done&id=` + id + '&uid=' + uid
    switch (checkout.payment_method) {
      case 'CreditCard':
        stripe
          .confirmPayment({
            elements,
            confirmParams: {
              payment_method_data: {
                billing_details: {
                  address: {
                    postal_code: checkout.billing_zip,
                    country: 'US',
                  },
                },
              },
              return_url: `${window.location.origin}/${return_url}`,
            },
            redirect: 'if_required',
          })
          .then(payload => {
            if (payload.error) {
              setIsWorking(false)
              dispatch(
                changeStatus({
                  status: CHECKOUT_STATUS_FAILED,
                  message: payload.error.message,
                  errors: [payload.error.message],
                  show: true,
                  highlight: 'checkout',
                })
              )
            } else {
              navigate(return_url)
            }
          })
        break
      case 'BankWire':
        navigate(return_url)
        break
      default:
        window.location.href = url
        break
    }
  }
  React.useEffect(() => {
    if (checkout.order_url || checkout.payment_method === 'BankWire') {
      setIsWorking(false)
    }
  }, [checkout.order_url, checkout.payment_method])

  const handleChange = async event => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    // setDisabled(event.empty);
    // console.log('event', event)
    setIsComplete(event.complete)
  }
  const disabled = !isComplete && checkout.payment_method === 'CreditCard'

  return (
    <section className="flex flex-col gap-4 mb-20 lg:mb-0">
      <section className="flex flex-col lg:flex-row gap-8 ">
        <div className="">
          <h2 className="pb-4 text-xl font-bold uppercase text-gray-500">SHIPPING DETAILS</h2>
          <div>
            {checkout.shipping_name} {checkout.shipping_last_name}
            <br />
            {checkout.shipping_address}
            {checkout.shipping_address2}
            <br />
            {checkout.shipping_city}, {checkout.shipping_state} {checkout.shipping_zip}
            <br />
            {checkout.email}
            <br />
            {checkout.phone}
          </div>
          <button
            onClick={e => {
              e.preventDefault()
              setStep(1)
            }}
            className="my-5 text-gray-500 hover:text-red-700"
          >
            Change
          </button>
        </div>
        <div className="lg:px-10 lg:border-l-2">
          <h2 className="pb-4 text-xl font-bold uppercase text-gray-500">BILLING DETAILS</h2>
          <div>
            {checkout.billing_name} {checkout.billing_last_name}
            <br />
            {checkout.billing_address}
            {checkout.billing_address2}
            <br />
            {checkout.billing_city}, {checkout.billing_state} {checkout.billing_zip}
            <br />
            {checkout.email}
            <br />
            {checkout.phone}
          </div>
          <button
            onClick={e => {
              e.preventDefault()
              setStep(1)
            }}
            className="my-5 text-gray-500 hover:text-red-700"
          >
            Change
          </button>
        </div>
      </section>

      <div className="flex w-full flex-col justify-center ">
        <h2 className=" text-3xl font-bold flex flex-row text-left uppercase text-gray-500 mb-2">
          PAYMENT METHOD
        </h2>

        <div className="fade-in-now uppercase flex flex-col ">
          {checkout.payment_method === 'CreditCard' && (
            <nobr className="md:text-xl ">
              <IconCreditCard /> CREDIT CARD
            </nobr>
          )}
          {checkout.payment_method === 'PayPal' && (
            <nobr>
              <IconPayPal /> PayPal
            </nobr>
          )}
          {checkout.payment_method === 'Affirm' && (
            <>
              <IconAffirm className={'w-16 h-auto mb-2'} />

              <p>
                10 % Restocking fee will be applied to all returns on the purchases that are subject
                to Affirm financing due to non-refundable transaction fees charged to the vendor.
              </p>
            </>
          )}
          {checkout.payment_method === 'BankWire' && (
            <>
              <nobr>
                <IconBank /> Wire Transfer
              </nobr>
              <p>
                * One of our Specialists will contact you with Wire Transfer Information soon after
                the order is completed.
              </p>
            </>
          )}
          {checkout.payment_method === 'BitPay' && (
            <nobr>
              <IconCoin /> BitPay
            </nobr>
          )}

          <button
            className="mt-2 text-gray-500 hover:text-red-700 order-2 text-left"
            disabled={isWorking}
            onClick={() => setStep(2)}
          >
            Change
          </button>
        </div>
      </div>

      {checkout.payment_method === 'CreditCard' && (checkout.order_url || '').length > 0 && (
        <PaymentElement
          id="payment-element"
          onChange={handleChange}
          options={{
            wallets: {
              applePay: 'auto',
              googlePay: 'auto',
            },
            fields: {
              billingDetails: {
                address: {
                  country: 'never',
                  postalCode: 'never',
                },
              },
            },
            defaultValues: {
              billingDetails: {
                address: {
                  postal_code: checkout.billing_zip,
                  country: 'US',
                },
              },
            },
          }}
        />
      )}

      <div className=" fixed md:static bottom-0 left-0 w-full flex flex-col lg:pb-0 z-10">
        <button
          className={
            'order-2 md:order-1 w-full text-center bg-red-700 text-white md:max-w-max  px-7 border-2 border-red-700 md:bg-white py-4 md:py-2 font-bold md:text-red-700 hover:border-red-700 hover:bg-red-700 hover:text-white duration-150' +
            (disabled ? ' opacity-50 cursor-not-allowed' : '')
          }
          disabled={isWorking || disabled}
          onClick={e => {
            e.preventDefault()
            finalizePayment()
          }}
        >
          {isWorking ? (
            <>
              <IconLoading className="text-gray-400 mr-4 animate-spin " /> PROCESSING ORDER
            </>
          ) : (
            <p className="animate-fade-in">COMPLETE ORDER</p>
          )}
        </button>
        {cartStatus === CHECKOUT_STATUS_FAILED && (
          <div className="text-red-700 h-8 md:mt-5 inline-block items-center justify-center  truncate px-5 md:px-0 fade-in order-1 md:order-2 bg-white">
            <IconAlert className={'animate-pulse mr-2'} />
            {cartStatusMessage}
          </div>
        )}
      </div>
    </section>
  )
}
CheckoutSummary.propTypes = {
  setStep: PropTypes.func,
}

export default CheckoutSummary
