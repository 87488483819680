import React from 'react'
import PropTypes from 'prop-types'

export const IconPayPal = ({ className }) => (
  <svg role={'img'} aria-label={'PayPal'} className={'icon icon-paypal' + className}>
    <use xlinkHref="#icon-paypal" />
  </svg>
)
export const IconPlus = ({ className }) => (
  <svg role={'img'} aria-label={'Plus'} className={'icon icon-plus ' + className}>
    <use xlinkHref="#icon-plus" />
  </svg>
)

export const IconAlert = ({ className }) => (
  <svg role={'img'} aria-label={'Alert'} className={'icon icon-alert ' + className}>
    <use xlinkHref="#icon-alert" />
  </svg>
)

export const IconCreditCard = ({ className }) => (
  <svg role={'img'} aria-label={'Credit Card'} className={'icon icon-credit-card ' + className}>
    <use xlinkHref="#icon-credit-card" />
  </svg>
)

export const IconCoin = ({ className }) => (
  <svg role={'img'} aria-label={'Coin'} className={'icon icon-coin ' + className}>
    <use xlinkHref="#icon-coin" />
  </svg>
)

export const IconBank = ({ className }) => (
  <svg role={'img'} aria-label={'Bank'} className={'icon icon-bank ' + className}>
    <use xlinkHref="#icon-bank" />
  </svg>
)

export const IconAffirm = ({ className }) => (
  <svg
    role={'img'}
    aria-label={'Affirm'}
    viewBox="0 0 82 32"
    className={'icon aspect-[80/32] icon-affirm_logo ' + className}
  >
    <path d="M6.572 18.031c-2.165 0-4.675 1.035-6.036 2.108l1.246 2.606c1.092-0.996 2.855-1.84 4.446-1.84 1.514 0 2.338 0.498 2.338 1.514 0 0.69-0.556 1.035-1.59 1.169-3.909 0.498-6.975 1.571-6.975 4.58 0 2.376 1.705 3.832 4.35 3.832 1.897 0 3.583-1.054 4.388-2.434v2.050h3.526v-8.604c0-3.545-2.472-4.982-5.691-4.982zM5.25 29.241c-0.996 0-1.495-0.479-1.495-1.284 0-1.495 1.667-1.993 4.714-2.319 0.019 1.993-1.341 3.602-3.219 3.602zM45.509 20.637v-2.223h-3.526v13.202h3.775v-6.362c0-3.028 1.82-3.909 3.104-3.909 0.498 0 1.169 0.153 1.61 0.479l0.69-3.487c-0.575-0.249-1.188-0.307-1.686-0.307-1.935 0-3.162 0.862-3.966 2.606zM68.675 18.031c-1.993 0-3.487 1.188-4.273 2.319-0.728-1.475-2.242-2.319-4.081-2.319-1.993 0-3.372 1.111-4.024 2.376v-1.993h-3.641v13.202h3.775v-6.802c0-2.434 1.284-3.602 2.472-3.602 1.073 0 2.069 0.69 2.069 2.491v7.914h3.794v-6.802c0-2.472 1.246-3.602 2.491-3.602 0.996 0 2.050 0.728 2.050 2.472v7.933h3.775v-9.121c0-2.97-1.993-4.465-4.407-4.465zM25.6 17.437v0.977h-5.729v-1.341c0-1.744 0.996-2.242 1.859-2.242 0.498 0 1.15 0.115 1.686 0.422l1.169-2.663c-0.69-0.402-1.82-0.766-3.334-0.766-2.414 0-5.154 1.36-5.154 5.634v0.977h-2.184v2.913h2.184v10.271h3.775v-10.29h5.729v10.29h3.775v-10.29h3.43v-2.913h-3.43v-1.341c0-1.744 0.996-2.242 1.859-2.242 0.958 0 1.686 0.422 1.686 0.422l1.169-2.663s-1.188-0.766-3.334-0.766c-2.395-0.019-5.154 1.341-5.154 5.614zM35.047 18.414h3.775v13.202h-3.775z" />
    <path
      fill="rgb(74, 74, 244)"
      d="M57.044 0c-10.194 0-19.277 7.071-21.844 16.172h3.698c2.146-6.783 9.447-12.723 18.146-12.723 10.577 0 19.698 8.048 19.698 20.58 0 2.817-0.364 5.346-1.054 7.588h3.583l0.038-0.115c0.594-2.319 0.881-4.829 0.881-7.473 0-13.969-10.175-24.029-23.147-24.029z"
    />
  </svg>
)

export const IconLoading = ({ className }) => (
  <svg
    role={'img'}
    aria-label={'Icon Loading'}
    className={'icon  ' + className}
    viewBox="0 0 32 32"
  >
    <path d="M27.802 5.197c-2.925-3.194-7.13-5.197-11.803-5.197-8.837 0-16 7.163-16 16h3c0-7.18 5.82-13 13-13 3.844 0 7.298 1.669 9.678 4.322l-4.678 4.678h11v-11l-4.198 4.197z"></path>
    <path d="M29 16c0 7.18-5.82 13-13 13-3.844 0-7.298-1.669-9.678-4.322l4.678-4.678h-11v11l4.197-4.197c2.925 3.194 7.13 5.197 11.803 5.197 8.837 0 16-7.163 16-16h-3z"></path>
  </svg>
)

export const IconCoupon = ({ className }) => (
  <svg
    role={'img'}
    aria-label={'Icon Coupon'}
    className={'icon  ' + className}
    viewBox="0 0 325 212"
  >
    <path
      d="M325,132.5c0-5.7-2.6-8.4-8.4-8.7c-9.4-0.5-16.8-8.3-16.8-17.9c0-9.5,7.5-17.3,16.9-17.7c5.6-0.3,8.2-3,8.2-8.5
	c0-17.9,0-35.8,0-53.7c0-15.4-10.5-25.9-25.8-25.9c-45.6,0-91.2,0-136.7,0c-45.7,0-91.5,0-137.2,0C10.8,0.1,0,10.8,0,25.3
	c0,18.1,0,36.1,0,54.2c0,5.7,2.6,8.4,8.4,8.7c9.4,0.5,16.8,8.3,16.8,17.9c0,9.5-7.5,17.3-16.9,17.7c-5.6,0.3-8.2,3-8.2,8.5
	c0,18.1,0,36.3,0,54.4c0,14.4,10.8,25.1,25.1,25.1c91.6,0,183.1,0,274.7,0c14.5,0,25.2-10.7,25.2-25.2
	C325,168.6,325,150.5,325,132.5z M310.3,185.9c0,7.3-3.9,11.3-11.2,11.3c-66.9,0-133.8,0-200.8,0c-0.8,0-1.6,0-2.7,0
	c0-2.6,0-5,0-7.5c-4.8,0-9.3,0-13.9,0c0,2.5,0,4.9,0,7.5c-1.1,0-2,0-2.8,0c-17.7,0-35.3,0-53,0c-7.1,0-11.1-4-11.1-11.1
	c0-15.3,0-30.5-0.1-45.8c0-1.9,0.6-2.7,2.4-3.2c13.8-4.3,22.9-16.7,22.9-31.1c0-14.3-9.2-26.8-22.9-31c-1.7-0.5-2.3-1.2-2.3-3
	c0.1-15.5,0-31,0.1-46.6c0-6.2,4.1-10.5,10-10.5c18.4-0.1,36.8,0,55.2,0c0.5,0,1,0.1,1.8,0.1c0,2.5,0,4.8,0,7.3c4.6,0,9.1,0,13.8,0
	c0-2.4,0-4.8,0-7.5c1.2,0,2.1,0,2.9,0c66.7,0,133.3,0,200,0c8.2,0,11.9,3.7,11.9,11.9c0,14.9,0,29.7,0,44.6c0,0.9,0,1.8,0,2.8
	c-0.8,0.3-1.6,0.6-2.3,0.8c-13.6,4.3-22.8,16.7-22.9,30.9c-0.1,14.2,8.9,26.8,22.5,31.1c2.3,0.7,2.8,1.7,2.8,3.9
	C310.2,155.8,310.3,170.9,310.3,185.9z M197.4,75.2c0.1-17.8-14.3-32.3-32.1-32.4c-17.9-0.1-32.3,14.2-32.4,32.1
	c-0.1,18,14.4,32.5,32.3,32.5C182.8,107.3,197.3,92.9,197.4,75.2z M147.6,74.4c0.2-9.5,8.3-17.1,17.8-16.9c9.8,0.2,17.5,8.3,17.3,18
	c-0.3,9.6-8.5,17.4-18,17.1C154.9,92.3,147.3,84.2,147.6,74.4z M227.1,104.7c-17.8,0-32.2,14.4-32.2,32.3
	c0,17.9,14.4,32.2,32.3,32.3c17.8,0,32.4-14.5,32.3-32.3C259.4,119.2,244.9,104.7,227.1,104.7z M226.9,154.6
	c-9.9-0.2-17.6-8.1-17.4-17.9c0.2-9.7,8.3-17.5,17.8-17.2c9.7,0.2,17.5,8.2,17.3,17.8C244.5,146.9,236.5,154.7,226.9,154.6z
	 M240.6,71.9c2.1-2.3,2.5-5.8,1-8.5c-1.5-2.7-4.6-4.5-7.7-3.6c-1.9,0.6-3.8,1.8-5.3,3.3c-19.7,19.5-39.3,39.2-58.9,58.8
	c-5.9,5.9-11.8,11.8-17.7,17.8c-2.4,2.4-3,5.2-1.7,8.4c1.1,2.7,3.8,4.4,6.8,4.3c0.1,0.2,0.3,0.4,0.4,0.6c1.9-1.2,4-2.1,5.5-3.6
	c24-23.8,47.9-47.8,71.8-71.7C236.8,75.8,238.7,73.9,240.6,71.9z M81.7,86.3c4.6,0,9.1,0,13.6,0c0-8.4,0-16.5,0-24.8
	c-4.6,0-9.1,0-13.6,0C81.7,69.9,81.7,78.1,81.7,86.3z M81.8,118.4c4.6,0,9.1,0,13.6,0c0-8.4,0-16.6,0-24.8c-4.6,0-9.1,0-13.6,0
	C81.8,102,81.8,110.1,81.8,118.4z M81.8,182.5c4.5,0,8.9,0,13.6,0c0-8.4,0-16.7,0-24.8c-4.6,0-9.1,0-13.6,0
	C81.8,166.1,81.8,174.2,81.8,182.5z M81.8,54.3c4.6,0,9.1,0,13.5,0c0-8.4,0-16.5,0-24.8c-4.6,0-9.1,0-13.5,0
	C81.8,37.9,81.8,46.1,81.8,54.3z M81.8,125.6c0,8.5,0,16.6,0,24.8c4.6,0,9.1,0,13.5,0c0-8.4,0-16.6,0-24.8
	C90.7,125.6,86.3,125.6,81.8,125.6z"
    />
  </svg>
)

export const IconCheckmark = ({ className }) => (
  <svg
    role={'img'}
    aria-label={'Icon Checkmark'}
    className={'icon  ' + className}
    viewBox="0 0 50 50"
  >
    <path d="M 11 4 C 7.101563 4 4 7.101563 4 11 L 4 39 C 4 42.898438 7.101563 46 11 46 L 39 46 C 42.898438 46 46 42.898438 46 39 L 46 15 L 44 17.3125 L 44 39 C 44 41.800781 41.800781 44 39 44 L 11 44 C 8.199219 44 6 41.800781 6 39 L 6 11 C 6 8.199219 8.199219 6 11 6 L 37.40625 6 L 39 4 Z M 43.25 7.75 L 23.90625 30.5625 L 15.78125 22.96875 L 14.40625 24.4375 L 23.3125 32.71875 L 24.09375 33.4375 L 24.75 32.65625 L 44.75 9.03125 Z" />
  </svg>
)

export const IconApplePay = ({ className }) => (
  <svg
    role={'img'}
    aria-label={'Icon Apple Pay'}
    className={'icon  ' + className}
    viewBox="0 0 30 13"
  >
    <path d="M6,0c-.5,0-1.1.3-1.5.8-.3.4-.6,1-.5,1.6.6,0,1.1-.3,1.5-.7.3-.4.6-1.1.5-1.7ZM10.8,1.2v9h1.4v-3h2c1.8,0,3.1-1.2,3.1-3s-1.3-3-3-3h-3.4ZM12.2,2.4h1.7c1.2,0,1.9.7,1.9,1.8s-.7,1.8-1.9,1.8h-1.7v-3.6ZM5.9,2.7c-.8,0-1.4.4-1.8.4s-1-.4-1.7-.4S.8,3.2.4,4c-.9,1.5-.2,3.7.6,5,.4.6.9,1.2,1.6,1.2.6,0,.8-.4,1.6-.4s1,.4,1.7.4,1.1-.6,1.5-1.2c.5-.7.7-1.4.7-1.4,0,0-1.3-.5-1.3-2,0-1.3,1-1.8,1.1-1.9-.6-.9-1.5-1-1.8-1h0ZM20.5,3.6c-1.5,0-2.6.8-2.6,2h1.3c.1-.5.6-.9,1.3-.9.9,0,1.3.4,1.3,1.1v.5h-1.8c-1.6.2-2.5.8-2.5,2s.9,1.9,2.2,1.9,1.7-.4,2.1-1.1h0v1.1h1.3v-4.4c0-1.3-1-2.1-2.7-2.1ZM23.7,3.6l2.4,6.6v.4c-.3.7-.7.9-1.3.9s-.3,0-.4,0v1.1c0,0,.4,0,.5,0,1.4,0,2-.5,2.6-2.1l2.5-6.9h-1.4l-1.7,5.3h0l-1.7-5.3h-1.5ZM21.8,7.2v.5c0,.8-.7,1.4-1.7,1.4s-1.2-.4-1.2-.9.5-.9,1.3-.9h1.6Z" />
  </svg>
)

export const IconAmazonPay = ({ className }) => (
  <svg
    role={'img'}
    aria-label={'Icon Amazon Pay'}
    className={'icon  ' + className}
    viewBox="0 0 30 19"
  >
    <path d="M7.9,0h-.2c-.7.1-1.3.4-1.8.8-.1,0-.2.2-.3.3,0,0,0,0,0,0,0-.1,0-.3,0-.4,0-.2-.2-.3-.4-.3h-.5c-.3,0-.4,0-.4.4v10.1c0,.2.1.2.2.2h1c.1,0,.2,0,.2-.2,0,0,0,0,0-.1v-3.5s0,0,.1,0c.9.7,1.8.9,2.9.7,1-.2,1.7-.8,2.1-1.7.3-.7.5-1.3.5-2.1,0-.8,0-1.6-.4-2.3-.4-.9-1.1-1.6-2.1-1.8-.2,0-.3,0-.5,0C8.1,0,8,0,7.9,0ZM15.6,0s0,0-.1,0c-.5,0-.9,0-1.4.2-.3,0-.6.2-.9.3-.2,0-.3.2-.3.4,0,.2,0,.3,0,.5,0,.2.1.3.3.2.4,0,.7-.2,1.1-.3.6-.1,1.2-.2,1.8,0,.3,0,.6.1.8.4.2.2.2.5.3.8,0,.4,0,.7,0,1.1s0,0,0,0h0c-.5-.1-1-.2-1.5-.2-.5,0-1.1,0-1.6.2-.6.2-1.1.6-1.4,1.2-.2.5-.3,1-.2,1.5.1.7.4,1.2,1,1.5.6.3,1.2.3,1.8.3.7-.1,1.4-.4,1.9-.9,0,0,0,0,0,0,0,.2,0,.4,0,.5,0,.2.1.2.3.3h.8c.1,0,.2-.1.2-.2,0,0,0,0,0-.1V2.5c0-.2,0-.4,0-.6,0-.6-.4-1.1-.9-1.5-.3-.2-.7-.3-1-.3-.2,0-.3,0-.5,0h-.6ZM19.7,0c-.1,0-.2.1-.2.2,0,0,0,.2,0,.3.9,2.3,1.9,4.6,2.8,6.9,0,.2,0,.4,0,.6-.2.3-.3.7-.4,1.1-.1.3-.4.5-.7.6-.2,0-.5,0-.7,0-.1,0-.2,0-.4,0-.2,0-.2,0-.3.2v.5c0,.3,0,.4.4.4.3,0,.5,0,.8,0,.8,0,1.5-.3,1.9-1,.2-.3.3-.6.4-.9,1.1-2.9,2.3-5.7,3.4-8.6,0,0,0-.2,0-.3,0-.1,0-.2-.2-.2h-.9c-.2,0-.3,0-.4.2,0,0,0,.1,0,.2l-1.7,4.8c-.1.3-.2.7-.4,1.1,0,0,0,0,0-.1-.6-1.7-1.2-3.4-1.8-5.1,0-.3-.2-.6-.3-.8,0-.1-.2-.2-.3-.2-.4,0-.7,0-1.1,0ZM7.9,1.2c.7,0,1.3.4,1.6,1.2.2.5.2,1,.2,1.5s0,.9-.2,1.4c-.3,1-1.1,1.4-2,1.4-.7,0-1.2-.3-1.8-.7,0,0,0,0,0-.2v-4c0,0,0-.1,0-.2.7-.5,1.4-.7,2.2-.6ZM15.4,4.4c.2,0,.4,0,.6,0,.4,0,.8.1,1.2.2,0,0,.1,0,.1.1,0,.2,0,.5,0,.7s0,.4,0,.7c0,0,0,.1,0,.1-.5.4-1.1.6-1.8.7-.3,0-.5,0-.8,0-.3-.1-.5-.4-.6-.6,0-.3,0-.6,0-.9.1-.4.4-.6.8-.7.2,0,.4,0,.6-.1ZM28,12c-.9,0-2,.2-2.9.8-.3.2-.2.4,0,.4.9-.1,3-.4,3.4.1.4.5-.4,2.5-.8,3.3-.1.3.1.4.4.2,1.6-1.3,2-4.1,1.7-4.5-.2-.2-.9-.4-1.9-.4ZM.2,13c-.2,0-.3.3,0,.5,3.9,3.7,9.1,5.5,14.8,5.5s8.9-1.3,12.1-3.9c.5-.4,0-1-.5-.8-3.7,1.6-7.7,2.4-11.3,2.4-5.4,0-10.6-1.1-14.8-3.7,0,0-.2,0-.3,0Z" />
  </svg>
)

export const IconGooglePay = ({ className }) => (
  <svg
    role={'img'}
    aria-label={'Icon Google Pay'}
    className={'icon  ' + className}
    viewBox="0 0 24 10"
  >
    <path d="M9,4.1h-4.1v1.5h2c-.2.3-.4.6-.7.9h0s0,0,0,0c-.5.4-1,.6-1.7.6-1.1,0-2.1-.7-2.4-1.7-.1-.3-.2-.6-.2-1,0-.5.2-1.1.4-1.5h0c.5-.7,1.3-1.2,2.1-1.2s1.3.3,1.8.7l1.3-1.4c-.8-.7-1.9-1.2-3.1-1.2S1.6.8.8,2c-.5.7-.8,1.6-.8,2.5s.2,1.4.5,2.1c.7,1.5,2.3,2.5,4,2.5s2.3-.5,3.1-1.2h0c.9-.8,1.4-2,1.4-3.3v-.4h0ZM21.1,7.2l-1.8-4.7h1.1l1.2,3.5,1.5-3.5h.9s-2.9,6.8-2.9,6.9h-.9s.9-2.2.9-2.2ZM18,5.4c0,.6-.5,1-1.2,1s-.9-.2-.9-.6.3-.6.9-.6h1.1c0,0,0,.3,0,.3ZM17.1,2.6c-1.1,0-1.8.6-1.9,1.4h.9c0-.4.4-.6.9-.6s.9.3.9.8v.3h-1.2c-1.2.1-1.8.6-1.8,1.4s.6,1.3,1.6,1.3,1.2-.3,1.5-.8h0v.8h.9v-3.1c0-.9-.7-1.5-1.9-1.5ZM12.6,4.1h-1.2V1.7h1.2c.9,0,1.4.4,1.4,1.2s-.6,1.2-1.4,1.2ZM12.9.8h-2.4v6.4h.9v-2.1h1.5c1.3,0,2.2-.9,2.2-2.1s-.9-2.1-2.1-2.1Z" />
  </svg>
)

function IconsCheckout() {
  return (
    <svg
      aria-hidden="true"
      className={'absolute h-0 w-0 overflow-hidden'}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
    >
      <defs>
        <symbol id="icon-paypal" viewBox="0 0 32 32">
          <path d="M29.063 9.644c-1.494 6.631-6.106 10.131-13.375 10.131h-2.419l-1.681 10.675h-2.025l-0.106 0.688c-0.069 0.456 0.281 0.863 0.738 0.863h5.175c0.613 0 1.131-0.444 1.231-1.050l0.050-0.262 0.975-6.181 0.063-0.337c0.094-0.606 0.619-1.050 1.231-1.050h0.769c5.012 0 8.938-2.038 10.088-7.925 0.431-2.238 0.269-4.137-0.712-5.55z" />
          <path d="M25.969 2.413c-1.481-1.688-4.163-2.413-7.587-2.413h-9.944c-0.7 0-1.3 0.506-1.406 1.2l-4.144 26.262c-0.081 0.519 0.319 0.988 0.844 0.988h6.144l1.544-9.781-0.050 0.306c0.106-0.694 0.7-1.2 1.4-1.2h2.919c5.731 0 10.219-2.325 11.531-9.063 0.038-0.2 0.075-0.394 0.1-0.581 0.387-2.487 0-4.188-1.35-5.719z" />
        </symbol>
        <symbol id="icon-plus" viewBox="0 0 32 32">
          <path d="M17.612 1.612h-3.223v12.777h-12.777v3.223h12.777v12.777h3.223v-12.777h12.777v-3.223h-12.777z" />
        </symbol>

        <symbol id="icon-alert" viewBox="0 0 32 32">
          <path d="M16 2.899l13.409 26.726h-26.819l13.409-26.726zM16 0c-0.69 0-1.379 0.465-1.903 1.395l-13.659 27.222c-1.046 1.86-0.156 3.383 1.978 3.383h27.166c2.134 0 3.025-1.522 1.978-3.383h0l-13.659-27.222c-0.523-0.93-1.213-1.395-1.903-1.395v0z" />
          <path d="M18 26c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2z" />
          <path d="M16 22c-1.105 0-2-0.895-2-2v-6c0-1.105 0.895-2 2-2s2 0.895 2 2v6c0 1.105-0.895 2-2 2z" />
        </symbol>
        <symbol id="icon-bank" viewBox="0 0 34 32">
          <path d="M32 30v-2h-2v-12h2v-2h-6v2h2v12h-6v-12h2v-2h-6v2h2v12h-6v-12h2v-2h-6v2h2v12h-6v-12h2v-2h-6v2h2v12h-2v2h-2v2h34v-2h-2z" />
          <path d="M16 0h2l16 10v2h-34v-2l16-10z" />
        </symbol>
        <symbol id="icon-coin" viewBox="0 0 32 32">
          <path d="M15 2c-8.284 0-15 6.716-15 15s6.716 15 15 15c8.284 0 15-6.716 15-15s-6.716-15-15-15zM15 29c-6.627 0-12-5.373-12-12s5.373-12 12-12c6.627 0 12 5.373 12 12s-5.373 12-12 12zM16 16v-4h4v-2h-4v-2h-2v2h-4v8h4v4h-4v2h4v2h2v-2h4l-0-8h-4zM14 16h-2v-4h2v4zM18 22h-2v-4h2v4z" />
        </symbol>
        <symbol id="icon-credit-card" viewBox="0 0 32 32">
          <path d="M29 4h-26c-1.65 0-3 1.35-3 3v18c0 1.65 1.35 3 3 3h26c1.65 0 3-1.35 3-3v-18c0-1.65-1.35-3-3-3zM3 6h26c0.542 0 1 0.458 1 1v3h-28v-3c0-0.542 0.458-1 1-1zM29 26h-26c-0.542 0-1-0.458-1-1v-9h28v9c0 0.542-0.458 1-1 1zM4 20h2v4h-2zM8 20h2v4h-2zM12 20h2v4h-2z" />
        </symbol>
      </defs>
    </svg>
  )
}
IconPayPal.propTypes = {
  className: PropTypes.string,
}
IconPlus.propTypes = {
  className: PropTypes.string,
}
IconAlert.propTypes = {
  className: PropTypes.string,
}
IconBank.propTypes = {
  className: PropTypes.string,
}
IconCoin.propTypes = {
  className: PropTypes.string,
}
IconCreditCard.propTypes = {
  className: PropTypes.string,
}
IconAffirm.propTypes = {
  className: PropTypes.string,
}

export default IconsCheckout
