import { useState, useEffect, useCallback } from 'react'
const isBrowser = typeof window !== 'undefined'

const useHashState = (key, defaultValue = '') => {
  const getHashValue = () => {
    if (isBrowser) {
      const searchParams = new URLSearchParams(window.location.hash.substring(1)) // remove the '#'
      return searchParams.has(key) ? decodeURIComponent(searchParams.get(key)) : defaultValue
    }
    return defaultValue
  }

  const [value, setValue] = useState(getHashValue)

  const onSetValue = useCallback(
    newValue => {
      if (isBrowser) {
        const searchParams = new URLSearchParams(window.location.hash.substring(1))
        searchParams.set(key, newValue)
        if(newValue === defaultValue || !newValue) {
          searchParams.delete(key)
        }
        // Update the hash without changing the entire hash string
        window.location.hash = searchParams.toString()
        setValue(newValue)
      }
    },
    [key]
  )

  useEffect(() => {
    const handleHashChange = () => {
      if (isBrowser) {
        setValue(getHashValue())
      }
    }

    if (isBrowser) {
      window.addEventListener('hashchange', handleHashChange)
    }
    return () => {
      if (isBrowser) {
        window.removeEventListener('hashchange', handleHashChange)
      }
    }
  }, [key, defaultValue])

  return [value, onSetValue]
}

export default useHashState
